import { ConstantsService } from './services/common/constants.service';
import localeAt from '@angular/common/locales/en-AT';
import { LiveApiModule } from 'chronos-live-client';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { RouterModule, UrlSerializer } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { appRoutes } from './app.routing';
import { environment } from '../environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { FullComponent } from './layouts/full/full.component';
import { ProfileComponent } from './layouts/full/profile/profile.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { OrganizationFilterComponent } from './layouts/full/organization-filter/organization-filter.component';
import { NotificationModule } from './notifications/notification.module';

import { ChronosCockpitModule } from './modules/chronos-cockpit/chronos-cockpit.module';
import { SpeedMonitorWidgetComponent } from './modules/speed-monitor/speed-monitor-widget/speed-monitor-widget/speed-monitor-widget.component';
import { SpeedMonitorModule } from './modules/speed-monitor/speed-monitor.module';
import { SpeedMonitorMyMachineModule } from './modules/speed-monitor-my-machine/speed-monitor-my-machine.module';

import {
  ChronosSharedModule,
  ApiAuthorizationModule,
  AUTHORIZE_INTERCEPTOR_MATCHER,
  AuthorizeInterceptor,
  createPrefixMatcher,
  HttpErrorInterceptor
} from 'chronos-shared';
import { MachineListModule } from './modules/machine-list/machine-list.module';
import { MachineChartModule } from './modules/machine-chart/machine-chart.module';
import { ChronosEnvironmentListModule } from './modules/chronos-environment-list/chronos-environment-list.module';
import { AppInitService } from './app-init.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ApmService, ApmModule } from '@elastic/apm-rum-angular';
import { LowerCaseUrlSerializer } from './services/custom/lowercase-url-serializer';
import { HttpSpinnerModule } from './modules/http-spinner/http-spinner.module';
import { TvModule } from './modules/tv/tv.module';
import { SpeedMonitorMyMachineComponent } from './modules/speed-monitor-my-machine/speed-monitor-my-machine/speed-monitor-my-machine.component';
import { Excel } from './services/common/excel.service';
import { registerLocaleData } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';

// NG-X Translation AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/localization/', `.json?cache-buster=${new Date().getTime()}`);
}

function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => appInitService.Init();
}

registerLocaleData(localeAt);
@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    AppSidebarComponent,
    OrganizationFilterComponent,
    ProfileComponent,
    SpeedMonitorWidgetComponent,
    SpeedMonitorMyMachineComponent
  ],
  imports: [
    ApmModule,
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(appRoutes),
    NotificationModule,
    LiveApiModule.forRoot({ rootUrl: environment.liveApiUrl }),
    ChronosSharedModule.forRoot({
      pandaRootUrl: environment.pandaApiUrl,
      coreRootUrl: environment.coreApiUrl,
      liveRootUrl: environment.liveApiUrl,
      signalrBaseUrl: environment.signalrBaseUrl
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: false
    }),
    ApiAuthorizationModule.forRoot({
      authority: '',
      clientId: ''
    }),
    ChronosCockpitModule,
    SpeedMonitorModule,
    SpeedMonitorMyMachineModule,
    MachineListModule,
    MachineChartModule,
    ChronosEnvironmentListModule,
    HttpSpinnerModule,
    TvModule
  ],
  exports: [TranslateModule],
  providers: [
    ApmService,
    DialogService,
    { provide: LOCALE_ID, useValue: 'en-AT' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    {
      provide: AUTHORIZE_INTERCEPTOR_MATCHER,
      useValue: createPrefixMatcher(environment.coreApiUrl),
      multi: true
    },
    {
      provide: AUTHORIZE_INTERCEPTOR_MATCHER,
      useValue: createPrefixMatcher(environment.liveApiUrl),
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    AppInitService,
    ConstantsService,
    Excel,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
