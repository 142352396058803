import { Component, OnInit, OnDestroy, ViewEncapsulation, Renderer2 } from '@angular/core';
import { StyleService } from './services/custom/style.service';
import { Router, NavigationStart } from '@angular/router';
import { AccessManagementService } from './services/custom/access-management.service';
import { filter, switchMap, catchError, retry } from 'rxjs/operators';
import { ChronosCockpitComponent } from './modules/chronos-cockpit/chronos-cockpit/chronos-cockpit.component';
import { SpeedMonitorWidgetComponent } from './modules/speed-monitor/speed-monitor-widget/speed-monitor-widget/speed-monitor-widget.component';
import { SpeedMonitorMyMachineComponent } from './modules/speed-monitor-my-machine/speed-monitor-my-machine/speed-monitor-my-machine.component';
import { MachineChartWidgetComponent } from './modules/machine-chart/machine-chart-widget/machine-chart-widget.component';
import { MachineListWidgetComponent } from './modules/machine-list/machine-list-widget/machine-list-widget.component';
import { ChronosEnvironmentListComponent } from './modules/chronos-environment-list/chronos-environment-list.component';
import { environment } from '../environments/environment';
import { EMPTY, Subscription, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GeneralSettingsCustomService } from './services/custom/general-settings-custom.service';
import { AuthenticationService } from './services/custom/authentication.service';
import moment from 'moment-mini';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { User } from './services/custom/_models/user';
import { globalVariable } from './services/custom/authentication.constant';
import { WebSocketClientService } from 'chronos-core-client';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalConfirmComponent } from 'chronos-shared';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  private themeName = environment.theme;
  private activeSubscriptions: Subscription[] = [];
  // private themeName = 'mm-theme'; // "demo-theme"   // choose any dynamic theme
  // This cannot be moved to local scope because then we get reference error.
  private momentLocaleSubscription: Subscription;
  public appBrowserTitle = 'Chronos Live';
  private connectionModal: DynamicDialogRef;

  constructor(
    private styleService: StyleService,
    private router: Router,
    private accessManagementService: AccessManagementService,
    private generalSettingsCustomService: GeneralSettingsCustomService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private titleService: Title,
    private location: Location,
    private webSocketClientService: WebSocketClientService,
    private dialogService: DialogService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {
    this.matIconRegistry.addSvgIcon('traceability', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/traceability.svg'));
    this.actionOnNavigationEnd();
    const subscription = this.authenticationService.currentUserSubject.subscribe((loggedInUser) => {
      if (loggedInUser) {
        this.authenticationService.getUserAuth().subscribe((result) => {
          const user: User = {
            userId: result.userId,
            orid: result.orid,
            usid: result.usid,
            response: result.response,
            locale: result.locale
          };

          if (loggedInUser.locale !== user.locale) {
            sessionStorage.setItem(globalVariable.CurrentUserObject, JSON.stringify(user));
            this.authenticationService.currentUserSubject.next(user);
            this.translateService.use(user.locale);
            this.setMomentLocale(user.locale.substr(0, 2));
            this.translateService.setDefaultLang(user.locale);
          } else {
            this.translateService.use(loggedInUser.locale);
            this.setMomentLocale(loggedInUser.locale.substr(0, 2));
            this.translateService.setDefaultLang(loggedInUser.locale);
          }
        });
      } else {
        this.setMomentLocale(environment.defaultLocale.substr(0, 2));
        this.translateService.setDefaultLang(environment.defaultLocale);
      }
    });
    this.activeSubscriptions.push(subscription);

    // this language will be used as a fallback when a translation isn't found in the current language
    // this.translateService.setDefaultLang(environment.defaultLocale);

    // HACK!! creating instance of every widget, so @Widget decorator gets called even when compiled with --configuration production
    // should be removed when clean approach is available

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const widgets = [
      new ChronosCockpitComponent(),
      new SpeedMonitorWidgetComponent(null),
      new MachineChartWidgetComponent(),
      new MachineListWidgetComponent(),
      new ChronosEnvironmentListComponent(),
      new SpeedMonitorMyMachineComponent(null, null)
    ];
  }

  private setMomentLocale(locale: string) {
    // https://momentjs.com/docs/#/customization/
    // Add other momentjs translations in future
    this.momentLocaleSubscription = this.translateService.get('MomentTranslations.RelativeTime').subscribe((relativeTimeTranslations) => {
      // subscription is needed because the Translation file is not loaded on first run.
      moment.updateLocale(locale, {
        relativeTime: relativeTimeTranslations
      });
      moment.locale(locale);
      if (this.momentLocaleSubscription) {
        this.momentLocaleSubscription.unsubscribe();
      }
    });
  }
  public ngOnInit() {
    if (this.authenticationService.currentUserValue !== null) {
      this.generalSettingsCustomService.getGeneralSettings(this.authenticationService.currentUserValue.orid).subscribe((result) => {
        if (result) {
          if (result.orSettings) {
            const orgSettings = JSON.parse(result.orSettings);
            if (orgSettings.ApplicationTheme) {
              this.themeName = `${orgSettings.ApplicationTheme}-theme`;
            }
          }
        }
        this.styleService.setMainTheme(this.themeName);
      });
    } else {
      this.styleService.setMainTheme(this.themeName);
    }

    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        const machineId = this.location.path().substring(this.location.path().lastIndexOf('/') + 1, this.location.path().length);
        if (this.location.path().includes('machinechart') || this.location.path().includes('speedmonitor')) {
          this.titleService.setTitle(`${this.appBrowserTitle} (${machineId})`);
        } else {
          this.titleService.setTitle(this.appBrowserTitle);
        }
      }
    });

    // subscribe to connection status
    this.subscribeToConnectionStatus();
    this.setLoader();
  }

  /*
    action on navigation end
   */
  private actionOnNavigationEnd(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.setAuthFunctionOperationEntity();
    });
  }

  private setAuthFunctionOperationEntity(): void {
    this.accessManagementService.fetchAllAuthFunctionOperation();
    this.accessManagementService.fetchAllAuthWidgetOperation();
  }
  public ngOnDestroy(): void {
    this.activeSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  // subscribe to connection status
  private subscribeToConnectionStatus(): void {
    const connectionStatusSubscription = this.webSocketClientService
      .isDisconnected()
      .subscribe((isDisconnected) => this.handleConnectionStatus(isDisconnected));
    this.activeSubscriptions.push(connectionStatusSubscription);
  }

  // handle connection status
  private handleConnectionStatus(isDisconnected: boolean): void {
    if (isDisconnected && !this.connectionModal) {
      this.openModalForConnectionLost();
      console.info('Chronos live connection lost');
    }

    if (!isDisconnected && this.connectionModal) {
      console.info('Chronos live connection available');
      this.chronosLiveAvability();
    }
  }

  // open modal for connection lost
  private openModalForConnectionLost(): void {
    this.connectionModal = this.dialogService.open(ModalConfirmComponent, {
      header: this.translateService.instant('WEB_SOCKET.CONNECTION_LOST'),
      data: {
        question: this.translateService.instant('WEB_SOCKET.QUESTION'),
        acceptable: true,
        subQuestion: this.translateService.instant('WEB_SOCKET.SUB_QUESTION'),
        hideFooter: true
      },
      closable: false
    });
  }

  // check chronos live api is running or not
  private chronosLiveAvability(): void {
    const subscription: Subscription = timer(0, 1000)
      .pipe(
        switchMap(() =>
          this.accessManagementService.getAclWidgets().pipe(
            catchError((error) => {
              console.error('Chronos live API error: ', error);
              return EMPTY;
            }),
            retry(3) // Retry up to 3 times before failing
          )
        )
      )
      .subscribe((result) => {
        if (result) {
          console.info('Chronos live API is running ', result);
          subscription.unsubscribe();
          this.closeConnectionModalAndReload();
        }
      });
  }

  // close modal and reload page
  private closeConnectionModalAndReload(): void {
    if (this.connectionModal) {
      this.connectionModal.close();
      this.connectionModal = null;
    }
    location.reload();
  }

  private setLoader(): void {
    const element = document.getElementById('loader');
    const storageVal = localStorage.getItem('loadingIndicator');

    if (!element) {
      return;
    }

    if (storageVal === null || storageVal === 'true') {
      this.renderer.setStyle(element, 'display', 'none');
    } else {
      this.renderer.setStyle(element, 'visibility', 'visible');
    }
  }
}
